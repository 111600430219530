import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import MenuTab from 'src/pages/MenuTab';
import MenuDetails from 'src/pages/MenuDetails';
import ProfileTab from 'src/pages/ProfileTab';
import ContactTab from 'src/pages/ContactTab';
import CartTab from 'src/pages/CartTab';
import PaymentPage from 'src/pages/PaymentPage';
import ProfileAuthPage from 'src/pages/ProfileAuthPage';
import ProfileMyDataPage from 'src/pages/ProfileMyDataPage';
import ProfileOrdersPage from 'src/pages/ProfileOrdersPage';
import ProfileOrderDetailsPage from 'src/pages/ProfileOrderDetailsPage';
import ProfileOrderThankYouPage from 'src/pages/ProfileOrderThankYouPage';
import ProfileVIPDetailsPage from 'src/pages/ProfileVIPDetailsPage';
import ProfileVIPPage from 'src/pages/ProfileVIPPage';
import StaticPage from 'src/pages/StaticPage';
import ComboCampaignPage from 'src/pages/ComboCampaignPage';
import NotFoundPage from 'src/pages/NotFoundPage';
import NoConnectionPage from 'src/pages/NoConnectionPage';
import { useBreakpoint, useCartPriceGetter } from 'src/hooks';
import { MOBILE_VIEW } from 'src/constants';
import { useDispatch, useSelector } from 'src/store';
import { formatPrice } from 'src/utils';
import pizzaIcon from 'src/assets/img/pizza-slice.svg';
import locationIcon from 'src/assets/img/location.svg';
import profileIcon from 'src/assets/img/profile.svg';
import cartIcon from 'src/assets/img/cart.svg';
import classes from './MainTabs.module.scss';
import textClasses from 'src/styles/text.module.scss';
import { selectDefaultCategory } from 'src/slices/product';

interface MainTabsProps extends RouteComponentProps {}
interface AuthGuardRouterProps extends RouteProps {
  component: React.FC<any>;
  isParameterized?: boolean;
}

const ParameterizedRoute = process.env.NODE_ENV !== 'development' ? Sentry.withSentryRouting(Route) : Route;

const isActiveTab = (currentPath: string, tabPath: string) => {
  const matchRegExp = new RegExp(tabPath, 'g');
  return !!currentPath.match(matchRegExp);
};

const AuthGuardRouter = ({ component: Component, isParameterized, ...routeProps }: AuthGuardRouterProps) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const AuthRoute = isParameterized ? ParameterizedRoute : Route;
  return (
    <AuthRoute
      {...routeProps}
      render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/tabs/menu" />)}
    />
  );
};

const MainTabs: React.FC<MainTabsProps> = ({ location }) => {
  const { t } = useTranslation();
  const { isMobileView } = useBreakpoint();
  const dispatch = useDispatch();
  const { isBottomNavigationDisplayed } = useSelector((state) => state.system);
  const path = location?.pathname;
  const { total } = useCartPriceGetter();
  const { cartItems } = useSelector((state) => state.cart);
  const { selectedSalePointId } = useSelector((state) => state.salePoint);
  const { hasInternetConnection } = useSelector((state) => state.system);

  const onOrderTabBtnClick = () => {
    dispatch(selectDefaultCategory());
  };

  if (!hasInternetConnection) {
    return <NoConnectionPage />;
  }

  return (
    <IonTabs>
      {/* Any route which expected to be indexed by search engine should be added to /utils/sitemap-routes */}
      <IonRouterOutlet animated={isMobileView}>
        <Route path="/tabs/:id">
          <NotFoundPage />
        </Route>
        <Redirect exact path="/" to="/tabs/menu" />
        <Redirect exact path="/tabs" to="/tabs/menu" />

        <Route exact path="/tabs/menu" component={MenuTab} />
        <Route exact path="/tabs/menu/:id" component={MenuTab} />
        <Route exact path="/tabs/menu/details" component={MenuDetails} />
        <Route exact path="/tabs/menu/combo-campaign" component={ComboCampaignPage} />

        <Route exact path="/lang/:lang" component={MenuTab} />

        <Route exact path="/tabs/profile" component={ProfileTab} />
        <Route exact path="/tabs/profile/auth" component={ProfileAuthPage} />
        <AuthGuardRouter exact path="/tabs/profile/my-data" component={ProfileMyDataPage} />
        <AuthGuardRouter exact path="/tabs/profile/orders" component={ProfileOrdersPage} />
        <AuthGuardRouter exact isParameterized path="/tabs/profile/orders/:id" component={ProfileOrderDetailsPage} />
        <AuthGuardRouter exact path="/tabs/profile/vip" component={ProfileVIPPage} />
        <AuthGuardRouter isParameterized path="/tabs/profile/vip/:id" component={ProfileVIPDetailsPage} />

        <Route exact path="/tabs/contact" component={ContactTab} />
        <ParameterizedRoute exact path="/tabs/contact/:name" component={StaticPage} />

        <Route exact path="/tabs/cart" component={CartTab} />
        <Route exact path="/tabs/cart/payment" component={PaymentPage} />

        <ParameterizedRoute exact path="/tabs/orders/:id/thank-you" component={ProfileOrderThankYouPage} />
      </IonRouterOutlet>

      <IonTabBar slot="bottom" className={classNames(MOBILE_VIEW, { 'ion-hide': !isBottomNavigationDisplayed })}>
        <IonTabButton tab="menu" href="/tabs/menu" className={classes.tabBtn}>
          {/* Next div is a workaround for a bug: ionic react IonTabButton overrides user onClick handler - https://github.com/ionic-team/ionic-framework/issues/22511 */}
          {/* eslint-disable-next-line */}
          <div onClick={onOrderTabBtnClick} className={classes.tabBtnClickArea} />
          <IonIcon src={pizzaIcon} />
          <IonLabel className={classNames({ [classes.tabBtnText]: !isActiveTab(path, '/tabs/menu') })}>
            {t('Order')}
          </IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" href="/tabs/profile" className={classes.tabBtn}>
          <IonIcon src={profileIcon} />
          <IonLabel className={classNames({ [classes.tabBtnText]: !isActiveTab(path, '/tabs/profile') })}>
            {t('Profile')}
          </IonLabel>
        </IonTabButton>
        <IonTabButton tab="contact" href="/tabs/contact" className={classes.tabBtn}>
          <IonIcon src={locationIcon} />
          <IonLabel className={classNames({ [classes.tabBtnText]: !isActiveTab(path, '/tabs/contact') })}>
            {t('Contact')}
          </IonLabel>
        </IonTabButton>
        <IonTabButton tab="cart" href="/tabs/cart" className={classes.tabBtn} disabled={!selectedSalePointId}>
          <IonIcon src={cartIcon} className={classes.cartIcon} />
          <IonLabel className={classNames({ [classes.tabBtnText]: !isActiveTab(path, '/tabs/cart') })}>
            {t('Cart')}
          </IonLabel>
          {cartItems.length > 0 && (
            <div className={classes.cartPriceTag}>
              <IonText color="primary" className={textClasses.textS}>
                {formatPrice(total)}
              </IonText>
            </div>
          )}
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
