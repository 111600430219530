import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Toaster } from 'react-hot-toast';

import MainTabs from 'src/pages/MainTabs';
import AppUrlListener from 'src/components/AppUrlListener';
import NotFoundPage from 'src/pages/NotFoundPage';
import SplashScreen from 'src/components/SplashScreen';
import ProductDetailsModal from 'src/components/modals/ProductDetailsModal';
import ComboCampaignModal from 'src/components/modals/ComboCampaignModal';
import ComboCampaignProductsModal from 'src/components/modals/ComboCampaignProductsModal';
import ComboCampaignProductComponentsModal from 'src/components/modals/ComboCampaignProductComponentsModal';
import SalePointPickerModal from 'src/components/modals/SalePointPickerModal';
import AuthModal from 'src/components/modals/AuthModal';
import AddVipModal from 'src/components/modals/AddVipModal';
import LoadingOverlay from './components/LoadingOverlay';
import { useDataLoading, useAppLanguageReload, useAppUpdateNotification } from 'src/hooks/';
import { useSelector } from './store';
import classes from './App.module.scss';
import './plugins/numeral';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* React perfect scrollbar CSS */
import 'react-perfect-scrollbar/dist/css/styles.css';

/* Swiper CSS */
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

/* Theme variables */
import './theme/variables.css';

/* Local global styles */
import './styles/index.scss';

const App: React.FC = () => {
  const { isDataLoaded } = useDataLoading();
  const { app: isLoading } = useSelector((state) => state.system.loading);
  const appInitialized = isDataLoaded;
  useAppLanguageReload();
  useAppUpdateNotification(appInitialized);

  return (
    <IonApp>
      <Toaster position="top-center" containerClassName={classes.toastContainer} />

      <IonReactRouter>
        <AppUrlListener />
        <SalePointPickerModal />
        <ProductDetailsModal />
        <ComboCampaignModal />
        <ComboCampaignProductsModal />
        <ComboCampaignProductComponentsModal />
        <AuthModal />
        <AddVipModal />
        {isLoading && <LoadingOverlay backdrop spinnerColor="primary" />}
        {appInitialized && (
          <IonRouterOutlet>
            <Route path="*">
              <NotFoundPage />
            </Route>
            <Route path="/tabs/:id" component={MainTabs} />
            <Route exact path="/">
              <Redirect to="/tabs/menu" />
            </Route>

            <Route exact path="/lang">
              <Redirect to="/tabs/menu" />
            </Route>
            <Route exact path="/lang/:lang" component={MainTabs} />
            <Route exact path="/tabs">
              <Redirect to="/tabs/menu" />
            </Route>
          </IonRouterOutlet>
        )}
      </IonReactRouter>
      {!appInitialized && <SplashScreen />}
    </IonApp>
  );
};

export default App;
